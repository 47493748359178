import {DoubleSide, LinearEncoding, SpotLightHelper, TextureLoader, Vector3} from "three";
import {useFrame, useLoader, useThree} from "@react-three/fiber";
import {useEffect, useMemo, useRef, useState} from "react";
import {Html, Image, useCamera, useGLTF, useHelper} from "@react-three/drei";
import { FacebookShareButton, FacebookIcon } from 'react-share';
import myFrame from "../assets/frame.glb"

function Artwork({imageUrl, position, name, rotation, lerping, paragraph, facebookLink, visualName}) {


	const colorMap = useLoader(TextureLoader, imageUrl);
	const aspectRatio = colorMap.image.width / colorMap.image.height;

	const theMesh = useRef();

	// const {nodes, materials} = useGLTF("/frame.glb");


	// const newFrame = useMemo(() => frame.scene.clone(), [frame]);

	const {camera} = useThree();

	const [hovering, setHovering] = useState(false);

	return (
		<group position={[position[0], position[1], position[2]]} rotation={[rotation[0], rotation[1], rotation[2]]} key={name}>

			{/*<primitive object={newFrame} rotation={[0, Math.PI, 0]} scale={[5.67 * aspectRatio, 6.1 * 1.39726027, 6]} position={[0, 0, 0]}/>*/}

			<mesh ref={theMesh} name={name} position={[0, 0, 0.00355]} castShadow>
				<planeBufferGeometry args={[1 * aspectRatio, 1]}/>
				<meshBasicMaterial args={[{map: colorMap}]}/>
			</mesh>

			<Html position={[-aspectRatio * 0.5, 0.499, 0]} style={{
				display: lerping ? "flex" : "none",
				cursor: "help",
				flexDirection: "row-reverse",
			}}>
				<div style={{
					display: hovering ? "flex" : "none",
					background: "rgba(0,0,0,0.45)",
					width: `${20 * aspectRatio}vw`,
					alignItems: "center",
					justifyContent: "center",
					borderRadius: 20,
					padding: 4,
				}}>
					<p style={{color: "white"}}>
						{paragraph}
					</p>
				</div>


				<div onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} style={{
					background: "rgba(0,0,0,0.45)",
					width: 50,
					height: 50,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: 100,
				}}>
					<span style={{color: "white"}}>
						?
					</span>
				</div>

			</Html>

			<Html position={[-0.05 * aspectRatio, -0.64, 0]} style={{display: lerping ? "flex" : "none"}}>
				<FacebookShareButton
					url={facebookLink}
				>
					<div id="fb-share-button">
						<svg viewBox="0 0 12 12" preserveAspectRatio="xMidYMid meet">
							<path className="svg-icon-path" d="M9.1,0.1V2H8C7.6,2,7.3,2.1,7.1,2.3C7,2.4,6.9,2.7,6.9,3v1.4H9L8.8,6.5H6.9V12H4.7V6.5H2.9V4.4h1.8V2.8 c0-0.9,0.3-1.6,0.7-2.1C6,0.2,6.6,0,7.5,0C8.2,0,8.7,0,9.1,0.1z"></path>
						</svg>
						<span>Share</span>
					</div>
				</FacebookShareButton>
			</Html>


		</group>
	);
}

export default Artwork;
