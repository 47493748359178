import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Canvas, useFrame} from "@react-three/fiber";
import {Suspense, useRef} from "react";
import {
    ACESFilmicToneMapping,
    CineonToneMapping,
    LinearEncoding,
    LinearToneMapping, NoToneMapping,
    ReinhardToneMapping,
    sRGBEncoding,
} from "three";

ReactDOM.render(
  <React.StrictMode>
      <Canvas gl={{alpha: false, outputEncoding: LinearEncoding, antialias: true }} shadowMap camera={{fov: 40, position: [0, 1.5, 0]}} >
          <Suspense fallback={null}>
              <App />
          </Suspense>
      </Canvas>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
