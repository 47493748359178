export const myData = {
    "art": [
        {
            "id": "14",
            "name": "still life",
            "description": "still life with black ink",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image1.jpg"
        },
        {
            "id": "17",
            "name": "Surviving Hand",
            "description": "coloured pencils, oil pastels",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image2.jpg"
        },
        {
            "id": "31",
            "name": "self portrait",
            "description": "coloured pens 35*50cm",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image22.jpg"
        },
        {
            "id": "32",
            "name": "Islamic museum detail",
            "description": "dip in inks 35*50cm",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image4.jpg"
        },
        {
            "id": "33",
            "name": "model",
            "description": "pencils 35*50cm",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image5.jpg"
        },
        {
            "id": "35",
            "name": "Baron Impan Palace",
            "description": "sketch with coloured pens",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image6.jpg"
        },
        {
            "id": "36",
            "name": "self-portrait (Dare)",
            "description": "35*25cm coloured pencils",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image24.jpg"
        },
        {
            "id": "38",
            "name": "21st birthday self portrait",
            "description": "pencils 25*35cm",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image23.webp"
        },
        {
            "id": "39",
            "name": "childhood photo with my sister",
            "description": "coloured pencils A4 size",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image10.jpg"
        },
        {
            "id": "40",
            "name": "The Eavesdropping",
            "description": "oil on canvas 25*40cm",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image11.jpg"
        },
        {
            "id": "41",
            "name": "The Farm",
            "description": "pencils 35*50cm",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image12.jpg"
        },
        {
            "id": "43",
            "name": "a scene from the polar express",
            "description": "coloured pencils A4 size",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image13.jpg"
        },
        {
            "id": "45",
            "name": "model in the park",
            "description": "water colours\r\n50*35cm",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image14.jpg"
        },
        {
            "id": "46",
            "name": "detail in qalawun complex",
            "description": "coloured pencils on brown paper\r\n35*50cm",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image15.jpg"
        },
        {
            "id": "47",
            "name": "king tut mask",
            "description": "coloured pencils A4",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image16.jpg"
        },
        {
            "id": "48",
            "name": "a scene of (corpse bride) movie",
            "description": "coloured pencils A4",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image17.jpg"
        },
        {
            "id": "49",
            "name": "Head of Apollo statue",
            "description": "pencils A4",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image18.jpg"
        },
        {
            "id": "50",
            "name": "Jim Raynor from Starcraft",
            "description": "oil on canvas",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image19.jpg"
        },
        {
            "id": "51",
            "name": "still life",
            "description": "pencils 50*70cm",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image20.jpg"
        },
        {
            "id": "52",
            "name": "A scene from *Home Alone* movie",
            "description": "coloured pencils A4",
            "facebookLink": "https://facebook.com",
            "imageUrl": "/img/image21.jpg"
        }
    ],
    "success": 1
}