import {
	BufferAttribute,
	DoubleSide,
	RepeatWrapping,
	TextureLoader,
} from "three";
import {useLoader} from "@react-three/fiber";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import height from "../assets/height.png";
import color from "../assets/color.jpg";
import normal from "../assets/normal.jpg";
import amocc from "../assets/amocc.jpg";
import roughness from "../assets/roughness.jpg";

function Floor() {

	const [colorMap, heightMap, normalMap, amOccMap, roughnessMap] = useLoader(TextureLoader,
		[color, height, normal, amocc, roughness,])

	colorMap.repeat.set(8,8)
	heightMap.repeat.set(8,8)
	normalMap.repeat.set(8,8)
	amOccMap.repeat.set(8,8)
	roughnessMap.repeat.set(8,8)

	colorMap.wrapS = RepeatWrapping
	heightMap.wrapS = RepeatWrapping
	normalMap.wrapS = RepeatWrapping
	amOccMap.wrapS = RepeatWrapping
	roughnessMap.wrapS = RepeatWrapping

	colorMap.wrapT = RepeatWrapping
	heightMap.wrapT = RepeatWrapping
	normalMap.wrapT = RepeatWrapping
	amOccMap.wrapT = RepeatWrapping
	roughnessMap.wrapT = RepeatWrapping

	const geomRef = useRef()

	useEffect(() => {
		if(geomRef.current){
			geomRef.current.setAttribute("uv2", new BufferAttribute(geomRef.current.attributes.uv.array, 2))
		}
	}, [])

	return (
		<group rotation={[-Math.PI * 0.5, 0, 0]} position={[0, 0.1, 0]}>
			<mesh >
				<planeBufferGeometry args={[20, 20, 100, 100]} />
				<meshStandardMaterial map={colorMap} bumpMap={heightMap} normalMap={normalMap} aoMap={amOccMap} roughnessMap={roughnessMap} side={DoubleSide} />
			</mesh>
		</group>
	);
}

export default Floor;
