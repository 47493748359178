import {Canvas, useFrame, useLoader, useThree} from "@react-three/fiber";
import {Suspense, useEffect, useRef, useState} from "react";
import Artwork from "./components/Artwork";
import {buttonGroup, useControls} from "leva";
import {Quaternion, Vector3} from "three";
import Floor from "./components/Floor";
import House from "./components/House";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {myData} from "./data";
import myFrame from "./assets/frame.glb"

function App() {

	const canvas = useRef();

	let clickedKeys = [];

	const artRef = useRef();

	const clickedArt = useRef();

	const [clickedObject, setClickedObject] = useState(null);

	const [lerping, setLerping] = useState(false);

	const [artWorksData, setArtWorksData] = useState([]);

	let p = new Vector3();
	let q = new Quaternion();

	const {temp} = useControls({
		"motion": buttonGroup({
			"↑": () => {
				let dir = new Vector3();

				camera.getWorldDirection(dir);
				camera.position.add(dir.multiplyScalar(0.5));
			},
			"↓": () => {
				let dir = new Vector3();

				camera.getWorldDirection(dir);
				camera.position.add(dir.multiplyScalar(-0.5));
			},
			"←": () => {
				let dir = new Vector3();

				camera.getWorldDirection(dir);

				var axis = new Vector3(0, 1, 0);
				var angle = Math.PI / 2;
				dir.applyAxisAngle(axis, angle);

				camera.position.add(dir.multiplyScalar(0.5));
			},
			"→": () => {
				let dir = new Vector3();

				camera.getWorldDirection(dir);

				var axis = new Vector3(0, 1, 0);
				var angle = Math.PI / 2;
				dir.applyAxisAngle(axis, angle);

				camera.position.add(dir.multiplyScalar(-0.5));
			},
		}),
	});

	useFrame(({clock, camera}) => {
		let dir = new Vector3();

		// moving character
		// console.log(clickedKeys)
		if (clickedKeys.includes(38) || clickedKeys.includes(87)) {
			camera.getWorldDirection(dir);
			camera.position.add(dir.multiplyScalar(0.15));
		}

		if (clickedKeys.includes(39) || clickedKeys.includes(68)) {
			camera.getWorldDirection(dir);
			var axis = new Vector3(0, 1, 0);
			var angle = Math.PI / 2;
			dir.applyAxisAngle(axis, angle);

			camera.position.add(dir.multiplyScalar(-0.15));
		}

		if (clickedKeys.includes(40) || clickedKeys.includes(83)) {
			camera.getWorldDirection(dir);
			camera.position.add(dir.multiplyScalar(-0.15));
		}

		if (clickedKeys.includes(37) || clickedKeys.includes(65)) {
			camera.getWorldDirection(dir);

			var axis = new Vector3(0, 1, 0);
			var angle = Math.PI / 2;
			dir.applyAxisAngle(axis, angle);

			camera.position.add(dir.multiplyScalar(0.15));
		}


		if (camera.position.x > 8) {
			camera.position.x = 8;
		}

		if (camera.position.x < -8) {
			camera.position.x = -8;
		}

		if (camera.position.z > 8) {
			camera.position.z = 8;
		}

		if (camera.position.z < -8) {
			camera.position.z = -8;
		}

		if (lerping && p.y != 0) {
			camera.position.lerp(p, 0.025);
			camera.quaternion.slerp(q, 0.025);
		}


		camera.updateMatrix();
		camera.updateProjectionMatrix();

	});


	useEffect(() => {

		clickedArt.current = artRef.current.getObjectByName(clickedObject);

		if (clickedArt.current) {

			clickedArt.current.parent.localToWorld(p.set(0, 0, 2.5));
			clickedArt.current.parent.getWorldQuaternion(q);

		}

	}, [clickedObject, lerping]);

	const {camera} = useThree();

	// const frame = useLoader(GLTFLoader, "/frame.glb");

	let rotating = false;
	let lastMouseX = 0;


	useEffect(() => {

		camera.lookAt(new Vector3(0, 1.5, -9.99));


		//wet code because i am lazy mouse up same as touch start .. and so on
		document.addEventListener('mouseup', function (evt) {
			rotating = false;
		});

		document.addEventListener('mousedown', function (evt) {
			rotating = true;
		});

		document.addEventListener('touchstart', (e) => {
			rotating = true;
		});

		document.addEventListener('touchend', (e) => {
			rotating = false;
		});

		document.addEventListener('mousemove', (e) => {

			if (rotating) {

				camera.rotation.y += (e.x - lastMouseX) * 0.0015;

				setLerping(false);
			}

			lastMouseX = e.x;
		});

		document.addEventListener("touchmove", (e) => {

			if (rotating) {

				camera.rotation.y += (e.touches[0].screenX - lastMouseX) * 0.0015;
				setLerping(false);

			}
			lastMouseX = e.touches[0].screenX;
		});

		getItems();

	}, []);

	useEffect(() => {

		function handleKeyDown(e) {

			!clickedKeys.includes(e.keyCode) && clickedKeys.push(e.keyCode);
			setLerping(false);

		}

		function handleKeyUp(e) {

			for (var i = 0; i < clickedKeys.length; i++) {


				if (clickedKeys[i] === e.keyCode) {
					setLerping(false);
					clickedKeys.splice(i, 1);
				}

			}

		}

		document.addEventListener('keydown', handleKeyDown);
		document.addEventListener('keyup', handleKeyUp);
	}, [clickedKeys]);


	const position = [
		{position: [-6, 1.5, -9.999], rotation: [0, 2 * Math.PI, 0]},
		{position: [-3, 1.5, -9.999], rotation: [0, 2 * Math.PI, 0]},
		{position: [0, 1.5, -9.999], rotation: [0, 2 * Math.PI, 0]},
		{position: [3, 1.5, -9.999], rotation: [0, 2 * Math.PI, 0]},
		{position: [6, 1.5, -9.999], rotation: [0, 2 * Math.PI, 0]},

		{position: [9.9999, 1.5, -6], rotation: [0, -Math.PI * 0.5, 0]},
		{position: [9.9999, 1.5, -3], rotation: [0, -Math.PI * 0.5, 0]},
		{position: [9.9999, 1.5, 0], rotation: [0, -Math.PI * 0.5, 0]},
		{position: [9.9999, 1.5, 3], rotation: [0, -Math.PI * 0.5, 0]},
		{position: [9.9999, 1.5, 6], rotation: [0, -Math.PI * 0.5, 0]},

		{position: [-6, 1.5, 9.999], rotation: [0, Math.PI, 0]},
		{position: [-3, 1.5, 9.999], rotation: [0, Math.PI, 0]},
		{position: [0, 1.5, 9.999], rotation: [0, Math.PI, 0]},
		{position: [3, 1.5, 9.999], rotation: [0, Math.PI, 0]},
		{position: [6, 1.5, 9.999], rotation: [0, Math.PI, 0]},

		{position: [-9.9999, 1.5, -6], rotation: [0, Math.PI * 0.5, 0]},
		{position: [-9.9999, 1.5, -3], rotation: [0, Math.PI * 0.5, 0]},
		{position: [-9.9999, 1.5, 0], rotation: [0, Math.PI * 0.5, 0]},
		{position: [-9.9999, 1.5, 3], rotation: [0, Math.PI * 0.5, 0]},
		{position: [-9.9999, 1.5, 6], rotation: [0, Math.PI * 0.5, 0]},
	];

	function getItems() {

		let data = new FormData();
		//authentication

		//fetch(`${servicePath}get-list-example`, {
		// fetch(`https://nouraboelsoud.com/projects/3d-gallery/apis/getArtWorks.php`, {
		// 	method: 'POST',
		// 	body: data,
		// 	headers:
		// 		{
		// 			"Accept": "application/json",
		// 		},
		// }).then(function (res) {
		// 	return res.json();
		// }).then(function (data) {
		// 	setArtWorksData(data.art)
		// });
		setArtWorksData(myData.art)
	}


	return (
		<>
			<group ref={artRef} onClick={(e) => {
				e.stopPropagation();
				setClickedObject(e.object.name);
				setLerping(true);
			}}
				   onPointerMissed={() => setLerping(false)}>
				>

				{artWorksData.map((artWork, index) => {
					return <Artwork name={"art" + index + 1} visualName={artWork.name} lerping={lerping} paragraph={artWork.description} facebookLink={artWork.facebookLink} imageUrl={artWork.imageUrl} position={position[index].position} rotation={position[index].rotation}/>;
				})}

			</group>

			<directionalLight intensity={.5}/>

			<ambientLight intensity={.3}/>

			<House/>

			<Floor/>

		</>
	);
}

export default App;
