import {BoxBufferGeometry, DoubleSide, PointLightHelper, RepeatWrapping, SpotLightHelper, TextureLoader} from "three";
import {useLoader} from "@react-three/fiber";
import {useEffect, useRef} from "react";
import {useHelper} from "@react-three/drei";
import height from "../assets/height.png";
import color from "../assets/color.jpg";
import normal from "../assets/normal.jpg";
import amocc from "../assets/amocc.jpg";
import roughness from "../assets/roughness.jpg";

function Floor() {

    const [colorMap, heightMap, normalMap, amOccMap, roughnessMap] = useLoader(TextureLoader,
        [color, height, normal, amocc, roughness,])

    colorMap.repeat.set(3, 3)
    heightMap.repeat.set(3, 3)
    normalMap.repeat.set(3, 3)
    amOccMap.repeat.set(3, 3)
    roughnessMap.repeat.set(3, 3)

    colorMap.wrapS = RepeatWrapping
    heightMap.wrapS = RepeatWrapping
    normalMap.wrapS = RepeatWrapping
    amOccMap.wrapS = RepeatWrapping
    roughnessMap.wrapS = RepeatWrapping

    colorMap.wrapT = RepeatWrapping
    heightMap.wrapT = RepeatWrapping
    normalMap.wrapT = RepeatWrapping
    amOccMap.wrapT = RepeatWrapping
    roughnessMap.wrapT = RepeatWrapping

    const spotLight = useRef()

    useHelper(spotLight, PointLightHelper, 1)

    return (
        <group position={[0, 4.5, 0]} rotation={[-Math.PI * 0.5, 0, 0]}>
            <mesh position={[0, 0, 3]} receiveShadow>
                <boxBufferGeometry args={[20, 20, 15]}/>
                {/*<meshPhongMaterial  />*/}
                <meshStandardMaterial color={"grey"} side={DoubleSide} roughness={0.4} metalness={0.6}/>
            </mesh>

            <pointLight ref={spotLight} intensity={2} position={[0, 0, 5]}/>
        </group>
    );
}

export default Floor;
